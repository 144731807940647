
import { computed, defineComponent, PropType } from "vue";

import { toLocaleFR } from "@/firebase/utils";
import { ParsedData } from "@/firebase/parse/types";

export default defineComponent({
  name: "TableItemExpand",
  props: {
    item: {
      type: Object as PropType<ParsedData>,
      required: true,
    },
    isNextExpandable: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const rows = computed(() =>
      props.item.header.list
        .map((title, i) => {
          const eelarve = props.item.elements[0]?.list[i] || 0;
          const taitmine = props.item.elements[1]?.list[i] || 0;
          const precentageStr =
            eelarve === 0 ? 0 : `${((taitmine / eelarve) * 100).toFixed(1)} %`;
          return {
            title,
            eelarveStr: toLocaleFR(eelarve, 0),
            taitmineStr: toLocaleFR(taitmine, 0),
            precentageStr,
          };
        })
        .filter((i) => i.eelarveStr || i.taitmineStr)
    );
    return { rows };
  },
});
