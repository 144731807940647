
import { computed, defineComponent, PropType } from "vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { useUserStore } from "@/store/UserStore";
import { RouteName } from "@/router/types";
import { CSV_TYPE } from "@/common/types";

export default defineComponent({
  name: "DataNotLoaded",
  props: {
    dataType: {
      type: String as PropType<CSV_TYPE>,
      required: true,
    },
  },
  setup(props) {
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const municipality = computed(() => municipalityState.municipality);
    const userStore = useUserStore();
    const userState = userStore.getState();
    const user = computed(() => userState.userInfo);
    const userMunicipalitySlug = computed(() => user.value?.municipalitySlug)
    const isOwner = computed(
      () => user.value?.municipalitySlug === municipality.value?.uid
    );
    const title = computed(() => {
      const municipalityName = municipality.value?.name;
      if (!municipalityName) return "";
      if (!isOwner.value) {
        return `${municipalityName} ei ole veel ${
          props.dataType === CSV_TYPE.EELARVEANDMIK
            ? "eelarve andmiku"
            : "investeeringuid"
        } ülesse laadinud`;
      }
      return `${
        props.dataType === CSV_TYPE.EELARVEANDMIK
          ? "Eelarve andmik"
          : "Investeeringud"
      } pole veel ülesse laetud`;
    });

    const buttonTitle = computed(() =>
      isOwner.value ? "Lae üles" : "Tagasi pealehele"
    );
    return { municipality, isOwner, RouteName, title, buttonTitle, user, userMunicipalitySlug };
  },
});
