<template>
  <tr
    v-for="(row, i) of rows"
    :key="i"
    class="w-full divide-x hover:bg-gray-100 transition duration-300 ease-in-out"
    :class="i === rows.length - 1 && !isNextExpandable ? '' : 'border-b'"
  >
    <td class="py-2.5">
      <div class="ml-14 2xl:ml-16 text-md 2xl:text-base">
        {{ row.title }}
      </div>
    </td>
    <td class="px-4 py-2 text-right font-normal text-black">
      {{ row.eelarveStr }}
    </td>
    <td class="px-4 py-2 text-right font-normal text-black">
      {{ row.taitmineStr }}
    </td>
    <td class="px-4 py-2 text-right font-normal text-black">
      {{ row.precentageStr }}
    </td>
  </tr>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";

import { toLocaleFR } from "@/firebase/utils";
import { ParsedData } from "@/firebase/parse/types";

export default defineComponent({
  name: "TableItemExpand",
  props: {
    item: {
      type: Object as PropType<ParsedData>,
      required: true,
    },
    isNextExpandable: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const rows = computed(() =>
      props.item.header.list
        .map((title, i) => {
          const eelarve = props.item.elements[0]?.list[i] || 0;
          const taitmine = props.item.elements[1]?.list[i] || 0;
          const precentageStr =
            eelarve === 0 ? 0 : `${((taitmine / eelarve) * 100).toFixed(1)} %`;
          return {
            title,
            eelarveStr: toLocaleFR(eelarve, 0),
            taitmineStr: toLocaleFR(taitmine, 0),
            precentageStr,
          };
        })
        .filter((i) => i.eelarveStr || i.taitmineStr)
    );
    return { rows };
  },
});
</script>

<style scoped></style>
