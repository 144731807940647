
import { computed, defineComponent } from "vue";

const MONTHS: Record<string, string> = {
  "01": "Jaanuar",
  "02": "Veebruar",
  "03": "Märts",
  "04": "Aprill",
  "05": "Mai",
  "06": "Juuni",
  "07": "Juuli",
  "08": "August",
  "09": "September",
  "10": "Oktoober",
  "11": "Novemeber",
  "12": "Detsember",
};
export default defineComponent({
  name: "DateAsOf",
  props: {
    // expecting yyyy-mm-dd
    selectedDate: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const date = computed<string | null>(() => {
      if (!props.selectedDate) return null;
      const dateParts = props.selectedDate.split("-");
      if (dateParts.length !== 3) return null;
      if (isNaN(+dateParts[0]) || +dateParts[0] < 1000 || +dateParts[0] > 9999)
        return null;
      if (!MONTHS[dateParts[1]]) return null;
      if (isNaN(+dateParts[2]) || +dateParts[2] < 1 || +dateParts[2] > 31)
        return null;
      return `${dateParts[2]} ${MONTHS[dateParts[1]]} ${dateParts[0]}`;
    });
    return { date };
  },
});
