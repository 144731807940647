<template>
  <tr :class="rowClasses" @click="expand(item.group)">
    <td class="px-4 flex col-span-2" :class="item.expandable ? 'py-3' : 'py-2'">
      <div v-if="item.expandable">
        <div
          class="mr-4 bg-gray-100 shadow-sm rounded h-5 w-5 flex text-lg items-center justify-center text-gray-600"
        >
          <div v-if="expanded">-</div>
          <div v-else>+</div>
        </div>
      </div>
      <div
        :class="
          item.expandable
            ? 'text-md 2xl:text-base font-medium'
            : 'ml-9 text-primary-orange text-sm tracking-wider font-semibold whitespace-nowrap'
        "
      >
        {{ item.header.title }}
      </div>
    </td>
    <td
      :class="item.expandable ? 'py-3 font-medium' : 'py-2 font-semibold'"
      class="px-4 text-right text-base"
    >
      {{ eelarveStr }}
    </td>
    <td
      :class="item.expandable ? 'py-3 font-medium' : 'py-2 font-semibold'"
      class="px-4 text-right font-medium text-base"
    >
      {{ taitmineStr }}
    </td>
    <td
      :class="item.expandable ? 'py-3 font-medium' : 'py-2 font-semibold'"
      class="px-4 text-right font-medium text-base"
    >
      {{ percentageStr }}
    </td>
  </tr>

  <TableItemExpand
    v-if="expanded"
    :item="item"
    :isNextExpandable="isNextExpandable"
  />
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import TableItemExpand from "@/components/table/TableItemExpand.vue";
import { toLocaleFR } from "@/firebase/utils";
import { ParsedData } from "@/firebase/parse/types";

export default defineComponent({
  name: "TableItem",
  components: { TableItemExpand },
  emits: {
    onExpand: (code: unknown) => typeof code === "string",
  },
  props: {
    item: {
      type: Object as PropType<ParsedData>,
      required: true,
    },
    expandedCode: {
      type: String,
      required: false,
    },
    data: {
      type: Array as PropType<ParsedData[]>,
      required: true,
    },
    idx: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const eelarve = computed(() => props.item.elements[0]?.title || 0);
    const taitmine = computed(() => props.item.elements[1]?.title || 0);

    const eelarveStr = computed(() => toLocaleFR(eelarve.value, 0));
    const taitmineStr = computed(() => toLocaleFR(taitmine.value, 0));

    const percentageStr = computed(() => {
      if (eelarve.value === 0) return 0;
      return `${((taitmine.value / eelarve.value) * 100).toFixed(1)} %`;
    });

    const expand = (code: string) => {
      if (props.item.expandable) emit("onExpand", code);
    };

    const expanded = computed(
      () => props.item.expandable && props.expandedCode === props.item.group
    );

    const isSomeExpanded = computed(() => !!props.expandedCode);

    // If some row is expanded, but it is not this row
    const isGrayed = computed(() => !expanded.value && isSomeExpanded.value);

    const expandableClasses = computed(() => {
      const classes = [
        "cursor-pointer",
        "divide-x",
        "hover:bg-gray-100",
        "hover:bg-opacity-70",
        "transition",
        "duration-300",
        "ease-in-out",
      ];

      if (isGrayed.value) classes.push("text-gray-400");
      return classes;
    });

    const isNextExpandable = computed(
      () => !!props.data[props.idx + 1]?.expandable
    );

    const isBorderBottom = computed(() => {
      if (expanded.value) return true;
      if (!props.item.expandable) return false;
      return isNextExpandable.value;
    });

    const rowClasses = computed(() => {
      const classes = props.item.expandable
        ? expandableClasses.value
        : ["yellow-row bg-primary-light-orange", "text-primary-orange"];

      if (isBorderBottom.value) classes.push("border-b");

      return classes;
    });

    return {
      eelarveStr,
      taitmineStr,
      percentageStr,
      expand,
      expanded,
      rowClasses,
      isNextExpandable,
    };
  },
});
</script>

<style scoped></style>
