<template>
  <div class="relative pt-16 pb-16">
    <div
      class="hidden absolute top-0 inset-x-0 h-1/2 lg:block"
      aria-hidden="true"
    ></div>
    <div class="max-w-7xl mx-auto bg-indigo-600 lg:bg-transparent lg:px-8">
      <div class="lg:grid lg:grid-cols-12">
        <div
          class="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent"
        >
          <div
            class="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"
            aria-hidden="true"
          ></div>
          <div
            class="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0"
          >
            <div
              class="relative aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1"
            >
              <img
                class="object-cover object-center rounded-xl shadow-2xl h-112"
                src="../../assets/images/pold.jpg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          class="relative bg-indigo-600 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-xl lg:grid lg:grid-cols-10 lg:items-center"
        >
          <div
            class="hidden absolute inset-0 overflow-hidden rounded-xl lg:block"
            aria-hidden="true"
          >
            <svg
              class="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-indigo-500"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              />
            </svg>
            <svg
              class="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-indigo-500"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              />
            </svg>
          </div>
          <div
            class="relative max-w-md py-12 px-4 space-y-6 sm:py-16 sm:px-6 lg:p-0 lg:col-start-4 lg:col-span-6"
          >
            <h2 class="text-3xl font-normal text-white">
              {{ title }}
            </h2>
            <!-- TODO: if upload csv then municipalitySlug is needed -->
            <router-link
              :to="{ name: isOwner ? RouteName.UPLOAD_CSV : RouteName.HOME, params: {municipalitySlug: userMunicipalitySlug} }"
              class="block w-full py-3 px-5 text-center bg-white border border-transparent rounded-md shadow-md text-base font-medium text-indigo-700 hover:bg-gray-50 sm:inline-block sm:w-auto"
            >
              {{ buttonTitle }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { useUserStore } from "@/store/UserStore";
import { RouteName } from "@/router/types";
import { CSV_TYPE } from "@/common/types";

export default defineComponent({
  name: "DataNotLoaded",
  props: {
    dataType: {
      type: String as PropType<CSV_TYPE>,
      required: true,
    },
  },
  setup(props) {
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const municipality = computed(() => municipalityState.municipality);
    const userStore = useUserStore();
    const userState = userStore.getState();
    const user = computed(() => userState.userInfo);
    const userMunicipalitySlug = computed(() => user.value?.municipalitySlug)
    const isOwner = computed(
      () => user.value?.municipalitySlug === municipality.value?.uid
    );
    const title = computed(() => {
      const municipalityName = municipality.value?.name;
      if (!municipalityName) return "";
      if (!isOwner.value) {
        return `${municipalityName} ei ole veel ${
          props.dataType === CSV_TYPE.EELARVEANDMIK
            ? "eelarve andmiku"
            : "investeeringuid"
        } ülesse laadinud`;
      }
      return `${
        props.dataType === CSV_TYPE.EELARVEANDMIK
          ? "Eelarve andmik"
          : "Investeeringud"
      } pole veel ülesse laetud`;
    });

    const buttonTitle = computed(() =>
      isOwner.value ? "Lae üles" : "Tagasi pealehele"
    );
    return { municipality, isOwner, RouteName, title, buttonTitle, user, userMunicipalitySlug };
  },
});
</script>

<style scoped></style>
