<template>
  <div class="flex flex-col">
    <div class="overflow-x-auto">
      <div class="align-middle inline-block min-w-full">
        <div
          class="bg-white border-l border-r border-t border-gray-200 border-opacity-70 rounded-md overflow-hidden"
        >
          <table
            class="min-w-full bg-white border-b border-primary-teal border-opacity-5 rounded-md"
          >
            <thead class="bg-gray-100 border-b border-gray-200">
              <TableHeader />
            </thead>
            <tbody>
              <TableItem
                v-for="(item, idx) of noZeroesData"
                :key="item.group"
                :item="item"
                :expanded-code="expandedCode"
                :data="data"
                :idx="idx"
                @onExpand="onExpand"
              />
              <slot name="footer">
                <tr v-if="displayFooter">
                  <td
                    class="px-4 py-2 h-full total-row bg-primary-light-teal bg-opacity-50"
                  >
                    <div
                      class="ml-9 whitespace-nowrap text-left text-primary-teal font-bold tracking-wider text-sm uppercase"
                    >
                      {{ footerName }} kokku:
                    </div>
                  </td>
                  <td
                    v-for="(total, idx) in totals"
                    :key="idx"
                    :class="[
                      'px-4 py-2 total-row bg-primary-light-teal bg-opacity-50 text-primary-teal font-semibold text-right',
                      { 'rounded-br-md': idx === '2' },
                    ]"
                  >
                    <div v-if="idx !== '2'">
                      {{ toLocaleFR(total, 0) }}
                    </div>
                    <div v-else>
                      {{ calcPercentage(totals[0], totals[1]) }} %
                    </div>
                  </td>
                </tr>
              </slot>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType } from "vue";
import TableHeader from "@/components/table/TableHeader.vue";
import TableItem from "@/components/table/TableItem.vue";
import { ParsedData } from "@/firebase/parse/types";
import { toLocaleFR } from "@/firebase/utils";

export default defineComponent({
  name: "BaseTable",
  components: { TableItem, TableHeader },
  emits: {
    onExpand: (code: unknown) => typeof code === "string",
  },
  props: {
    data: {
      type: Array as PropType<ParsedData[]>,
      required: true,
    },
    displayFooter: {
      type: Boolean,
      default: false,
    },
    footerName: {
      type: String,
      default: "",
    },
    expandedCode: {
      type: String,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const onExpand = (code: string) => emit("onExpand", code);

    const noZeroesData = computed(() =>
      props.data.filter((d) => !!d.elements[0].title || !!d.elements[1].title)
    );
    // TODO: refactor this, and template value should be computed
    const totals = computed(() => {
      const result: Record<string, number> = {};
      noZeroesData.value.forEach((r) => {
        r.elements.forEach((e, index) => {
          const category = result[index];
          result[index] = category ? +category + +e.title : +e.title;
        });
      });
      return result;
    });

    // TODO: refactor this, template value computed
    const calcPercentage = (totalValue: number, partialValue: number) => {
      if (totalValue === 0 || (!totalValue && !partialValue)) return "";
      return ((partialValue / totalValue) * 100).toFixed(1);
    };

    return {
      onExpand,
      totals,
      toLocaleFR,
      calcPercentage,
      noZeroesData,
    };
  },
});
</script>
