import { ref, onMounted, onBeforeUnmount, Ref } from "vue";
import ResizeObserver from "resize-observer-polyfill";

interface UseResizeObserver {
  resizeRef: Ref;
  resizeState: Ref;
}

export const useResizeObserver = (): UseResizeObserver => {
  const resizeRef = ref();
  const resizeState = ref({});

  const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    entries.forEach((entry: ResizeObserverEntry) => {
      resizeState.value = entry.contentRect;
    });
  });

  onMounted(() => {
    resizeState.value = resizeRef.value.getBoundingClientRect();
    observer.observe(resizeRef.value);
  });

  onBeforeUnmount(() => {
    observer.unobserve(resizeRef.value);
  });

  return { resizeState, resizeRef };
};

export default useResizeObserver;
