
import { computed, defineComponent, PropType } from "vue";
import { useUserStore } from "@/store/UserStore";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import DataNotLoaded from "@/components/common/DataNotLoaded.vue";
import { CSV_TYPE } from "@/common/types";
import { RouteName } from "@/router/types";

export default defineComponent({
  name: "VaadeGuard",
  components: { DataNotLoaded },
  props: {
    routeName: {
      type: String as PropType<RouteName>,
    },
  },
  setup(props) {
    const userStore = useUserStore();
    const userState = userStore.getState();
    const user = computed(() => userState.userInfo);
    const userMunicipalitySlug = computed(() => user.value?.municipalitySlug);

    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const pathMunicipalitySlug = computed(
      () => municipalityState.municipality?.uid
    );
    const municipalitySubscribed = computed(
      () => municipalityState.municipality?.subscribed
    );

    const isUserPath = computed(
      () => userMunicipalitySlug.value === pathMunicipalitySlug.value
    );

    const data = computed<{
      loaded: boolean;
      data: boolean;
      type: CSV_TYPE | null;
    }>(() => {
      switch (props.routeName) {
        case RouteName.KOOND_VAADE:
          return {
            loaded: municipalityState.koondVaadeLoaded,
            data: !!municipalityState.koondVaade,
            type: CSV_TYPE.EELARVEANDMIK,
          };
        case RouteName.TULUD:
          return {
            loaded: municipalityState.tuludLoaded,
            data: !!municipalityState.tulud,
            type: CSV_TYPE.EELARVEANDMIK,
          };
        case RouteName.KULUD:
          return {
            loaded: municipalityState.kuludLoaded,
            data: !!municipalityState.kulud,
            type: CSV_TYPE.EELARVEANDMIK,
          };
        case RouteName.INVESTEERINGUD:
          return {
            loaded: municipalityState.investeeringudLoaded,
            data: !!municipalityState.investeeringud,
            type: CSV_TYPE.INVESTEERINGU,
          };
        default:
          return { loaded: false, data: false, type: null };
      }
    });

    const showData = computed(
      () =>
        data.value.loaded &&
        data.value.data &&
        (isUserPath.value || municipalitySubscribed.value)
    );
    return { showData, CSV_TYPE, data };
  },
});
