<template>
  <tr class="divide-x">
    <th
      scope="col"
      class="
        px-4
        py-3
        text-sm
        font-semibold
        tracking-wide
        text-gray-600
        uppercase
        text-left
        whitespace-nowrap
      "
    >
      Nimetus
    </th>
    <th
      scope="col"
      class="
        px-4
        py-3
        text-sm
        font-semibold
        tracking-wide
        text-gray-600
        uppercase
        whitespace-nowrap
      "
    >
      Eelarve
    </th>
    <th
      scope="col"
      class="
        px-4
        py-3
        text-sm
        font-semibold
        tracking-wide
        text-gray-600
        uppercase
        whitespace-nowrap
      "
    >
      Eelarve täitmine
    </th>
    <th
      scope="col"
      class="
        px-4
        py-3
        text-sm
        font-semibold
        tracking-wide
        text-gray-600
        uppercase
        whitespace-nowrap
      "
    >
      Täitmise %
    </th>
  </tr>
</template>