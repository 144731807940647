
import { computed, defineComponent, onMounted, PropType } from "vue";
import TableHeader from "@/components/table/TableHeader.vue";
import TableItem from "@/components/table/TableItem.vue";
import { ParsedData } from "@/firebase/parse/types";
import { toLocaleFR } from "@/firebase/utils";

export default defineComponent({
  name: "BaseTable",
  components: { TableItem, TableHeader },
  emits: {
    onExpand: (code: unknown) => typeof code === "string",
  },
  props: {
    data: {
      type: Array as PropType<ParsedData[]>,
      required: true,
    },
    displayFooter: {
      type: Boolean,
      default: false,
    },
    footerName: {
      type: String,
      default: "",
    },
    expandedCode: {
      type: String,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const onExpand = (code: string) => emit("onExpand", code);

    const noZeroesData = computed(() =>
      props.data.filter((d) => !!d.elements[0].title || !!d.elements[1].title)
    );
    // TODO: refactor this, and template value should be computed
    const totals = computed(() => {
      const result: Record<string, number> = {};
      noZeroesData.value.forEach((r) => {
        r.elements.forEach((e, index) => {
          const category = result[index];
          result[index] = category ? +category + +e.title : +e.title;
        });
      });
      return result;
    });

    // TODO: refactor this, template value computed
    const calcPercentage = (totalValue: number, partialValue: number) => {
      if (totalValue === 0 || (!totalValue && !partialValue)) return "";
      return ((partialValue / totalValue) * 100).toFixed(1);
    };

    return {
      onExpand,
      totals,
      toLocaleFR,
      calcPercentage,
      noZeroesData,
    };
  },
});
