<template>
  <div>
    <svg class="w-40 h-40" viewBox="0 0 42 42">
      <circle
        class="donut-hole"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="#fff"
      ></circle>
      <circle
        class="donut-ring"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        :stroke="colors[1]"
        stroke-width="8"
      ></circle>
      <circle
        class="donut-segment"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        :stroke="colors[0]"
        stroke-width="8"
        stroke-dasharray="0 100"
        stroke-dashoffset="25"
      ></circle>
      <g>
        <text
          x="15"
          y="23"
          fill="#374151"
          font-size="7px"
        >
          {{value.toFixed(0)}}%
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, watch } from "vue";
import { gsap } from "gsap";

export default defineComponent({
  components: {},
  props: {
    value: {
      type: Number,
      required: true,
    },
    colors: {
      type: Array,
      default: () => ["#8B5CF6", "#F8F4FC"],
    },
  },
  setup(props) {
    const chartStrokeDasharray = computed(
      () => `${props.value} ${100 - props.value}`
    );

    const changeChart = () => {
      gsap.to(".donut-segment", {
        strokeDasharray: chartStrokeDasharray.value,
      });
    }

    onMounted(() => changeChart());

    watch(
      () => props.value,
      () => changeChart()
    );

    return {};
  },
});
</script>
<style scoped></style>
