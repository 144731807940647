import { scaleOrdinal } from "d3";
import { ChartData } from "@/components/charts/chart-interfaces";

export function defineColorScale(
  colors: Array<string>,
  value: Array<ChartData>
) {
  return scaleOrdinal(
    value.map((d) => d.type),
    colors
  );
}

export function truncateString(string: string, maxCharNumber: number) {
  if (string.length >= maxCharNumber) {
    return string.substring(0, maxCharNumber) + "...";
  } else {
    return string;
  }
}
